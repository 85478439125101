<style scoped>
.hero-1{
  position:relative
}
.hero-1.banner-home{
  height:150px;
  background-image: url('~@/../public/images/mobile-banner.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hero-1.banner-home img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hero-1 .hero-content{
  height:100%;
  left:0;
  margin:auto;
  position:absolute;
  right:0;
  text-align:center;
  top:0;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  flex-direction:column;
  padding: 40px 10px 0;
}
.hero-1 .hero-content .hero-title{
  text-align:center;
  font-weight:800;
  color: #fff;
  z-index: 1;
  margin-bottom: 15px;
}
.global-search-blur-banner.hero-1 .hero-content .hero-title{
  position: relative;
  z-index: 0;
}
.event-search-location-filter{
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 3;
  border-radius: 6px;
  /* overflow: hidden; */
}
.search-wrapper {
  position: relative;
  z-index: 2;
}
.banner-home:after{
  content:"";
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background:0 0;
  background-color:rgba(0,0,0,.5)
}
.section-event-category {
  padding: 40px 0;
  position: relative;
}
.section-event-category .section-header h2{
  font-size:22px;
  line-height:22px;
  text-transform: capitalize;
  color: #04092c;
  font-weight: 800;
  position: relative;
}
.section-event-category .section-content.home-category-list ul li{
  width:18%;
  margin:0 15px
}
.section-event-category .section-content ul li{
  margin: 0px;
  position:relative;
  overflow: hidden;
  border-radius: 8px;
}
.section-event-category .section-content ul li a{
  border-radius: 8px;
}
.section-event-category .section-content.home-category-list ul li a .category-wrap {
  overflow: hidden;
  height: 100%;
  border-radius: 8px;
  position: relative;
}
.section-event-category .section-content.home-category-list ul li img{
  -moz-transition:all .3s ease-in-out 0s;
  -webkit-transition:all .3s ease-in-out 0s;
  -ms-transition:all .3s ease-in-out 0s;
  -o-transition:all .3s ease-in-out 0s;
  transition:all .3s ease-in-out 0s;
  border-radius:8px;
  width: 100%;
  height: 100%;
}
.section-event-category .section-content.home-category-list ul li a:hover img {
  transform: scale(1.2);
  filter: blur(3px);
}
.section-event-category .section-content ul li span.cate-name {
  display: block;
  content:'';
  left:0;
  width:100%;
  background-color:rgba(0,0,0,.4);
  position: absolute;
  color: #fff;
  text-align: center;
  text-decoration: none;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  height: 100%;
  border-radius: 8px;
}
.event-category-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0px -15px;
  position: relative;
}
.section-event-category .section-content.home-category-list ul li a .category-wrap .cate-name label{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
  width: 80%;
  line-height: 26px;
  height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  word-break: break-word;
}
.performer-slider {
  background-color: #fff;
  margin-bottom: 20px !important;
}
.performer-slider-wrapper{
  position: relative;
  min-height: 110px;
}
.performers-slide-item{
  padding: 10px 15px;
  border-radius: 6px;
  position: relative;
  background-color: rgb(234 55 8/10%);
  height: 120px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.performers-slide-item:hover{
  background-color: rgb(234 55 8/20%);
}
.performer-slider-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.performers-slide-item img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.performers-slide-item .performer-name{
  font-size: 16px;
  color: #04092c;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.performer-slider-details .search-ticket-btn{
  margin: 15px 0 0 0;
  width: auto;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 18px;
  background-color: rgb(234 55 8/70%);
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 6px;
  border: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.performer-slider-details .search-ticket-btn:hover{
  background-color: rgb(234 55 8)
}
.dis-none{
  display:none!important
}
.pindrop_cssloader_main:before{
  content:'';
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,.7);
  z-index:1
}
.pindrop_cssloader_main{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  right:0;
  background-color:transparent;
  z-index:1;
  align-items:center;
  justify-content:center;
  display:flex
}
.lds-ellipsis{
  display:inline-block;
  position:relative;
  width:80px;
  height:80px;
  z-index:2
}
.lds-ellipsis div{
  position:absolute;
  top:33px;
  width:13px;
  height:13px;
  border-radius:50%;
  background:#f08100;
  animation-timing-function:cubic-bezier(0,1,1,0)
}
.lds-ellipsis div:nth-child(1){
  left:8px;
  animation:lds-ellipsis1 .6s infinite
}
.lds-ellipsis div:nth-child(2){
  left:8px;
  animation:lds-ellipsis2 .6s infinite
}
.lds-ellipsis div:nth-child(3){
  left:32px;
  animation:lds-ellipsis2 .6s infinite
}
.lds-ellipsis div:nth-child(4){
  left:56px;
  animation:lds-ellipsis3 .6s infinite
}
/*.location-filter-wrapper{
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}*/
.location-filter{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.location-filter .section-header{
  margin-right: 20px;
}
.nearest-performer-slider{
  position: relative;
  min-height: 200px;
}
.nearest-performer-slider .performers-slide-item{
  background-color: rgb(245 161 0/20%);
}
.nearest-performer-slider .performers-slide-item:hover{
  background-color: rgb(245 161 0/35%);
}
.nearest-performer-slider .performers-slide-item .search-ticket-btn{
  background-color: rgb(240 129 0/70%);
}
.nearest-performer-slider .performers-slide-item .search-ticket-btn{
  background-color: rgb(240 129 0);
}
.nearest-performer-slider .pindrop_cssloader_main{
  margin-top: 50px;
}
.top-categories-section{
  padding: 0 0 40px 0;
  background-color: rgb(246 247 251);
}
.global-search-blur .top-categories-section{
}
.global-search-blur .top-categories-section .top-categories-section-wrapper{
  z-index: 1;
}
.top-categories-section-wrapper{
  margin-top: -70px;
  position: relative;
  z-index: 2;
}
.top-categories-section-wrapper .container{
  background-color: #fff;
  border-radius: 5px;
  padding: 40px 50px;
  box-shadow: 0px 2px 60px #00000014;;
}
.top-categories-wrapper .row{
  align-items: stretch;
}
.top-categories-wrapper .section-title{
  text-transform: capitalize;
  color: #04092c;
  font-weight: 600;
  margin: 0 0 10px 0;
  font-size: 24px;
  line-height: 28px;
}
.top-categories-wrapper .card{
  border: 0px;
  /*box-shadow: 0 5px 15px #ccc;*/
  border-radius: 5px;
}
.top-categories-wrapper .card-image{
  height: 220px;
}
.top-categories-wrapper .card-image img{
  height: 100%;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}
.top-categories-wrapper .card-body-wrapper{
  border: 1px solid #848484;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.top-categories-wrapper .card-body{
  padding: 12px 0px 12px 10px;
  flex: auto;
  height: 228px;
  overflow-y: auto;
  position: relative;
  margin-bottom: 10px;
}
.top-categories-wrapper .card-body::-webkit-scrollbar {
  width: 3px;
}
.top-categories-wrapper .card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.top-categories-wrapper .card-body::-webkit-scrollbar-thumb {
  background: #888;
}
.top-categories-wrapper .card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.top-categories-wrapper .card-body .no-records-found{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 22px;
  line-height: 28px;
  color: #04092c;
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: 500;
  padding: 0 15px;
}
.top-categories-wrapper .card-body a{
  padding: 7px 15px;
  margin-right: 10px;
  display: flex;
  border-radius: 6px;
  border: 1px solid #eee;
}
.top-categories-wrapper .card-body a:hover,
.top-categories-wrapper .card-body a:focus{
  /*background-color: rgb(245 161 0/10%);*/
  background-color: #f4f4f4;
  /*box-shadow: rgb(36 63 97 / 11%) 0px 2px 12px, rgb(36 63 97 / 12%) 0px 0.5px 2px;*/
}
.top-categories-wrapper .card-body a:not(:last-child){
  margin-bottom: 7px;
}
.top-categories-wrapper .event-slot{
  width: 100%;
}
.top-categories-wrapper .card-event-name{
  color: #04092c;
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 22px;
}
.top-categories-wrapper .category-name{
  font-size: 14px;
  line-height: 22px;
}
.top-categories-wrapper .category-name .event-type-label{
  color: #454545;
}
.top-categories-wrapper .category-name .event-type-label i{
  margin-right: 5px;
}
.top-categories-wrapper .category-name .category-label{
  /*color: #f08100;*/
  color: #222;
  font-weight: 500;
}
.view-more-events{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.view-more-events a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  color: #222 !important;
  background-color: rgb(34 34 34/10%) !important;
  border-radius: 5px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.view-more-events a i{
  margin-left: 10px;
}
.view-more-events a:hover{
  background-color: rgb(34 34 34/15%) !important;
}
.search-wrapper .header-search{
  position:relative
}
.search-wrapper .header-search .search-btn{
  position:absolute;
  left:12px;
  top:13px;
  border:0;
  padding:0;
  color:#fff;
  line-height:14px;
  width:17px;
  height:17px;
  background-image:url('~@/../public/images/header-search-icon.webp');
  background-repeat:no-repeat;
  background-position:center;
  background-size:17px;
  background-color: transparent;
}
.home-location-wrapper{
  position: relative;
  z-index: 2;
}
@media (min-width: 768px){
  .hero-1 .hero-content .hero-title {
    font-size: 36px;
  }
  .section-event-category .section-header h2{
    font-size: 27px;
    line-height: 27px;
    margin: 0 0 20px;
  }
}
@media (min-width: 1024px){
  .hero-1.banner-home {
    height: 300px;
    background-image: url('~@/../public/images/banner.webp');
  }
  .hero-1 .hero-content .hero-title {
    font-size: 52px;
    line-height: 52px;
  }
  .section-event-category{
    margin: 0 auto;
    max-width: calc(1368px + 50rem);
  }
  .section-event-category .section-header h2{
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 1600px){
  .section-event-category .container,
  .location-filter-wrapper .container,
  .top-categories-section .container{
    max-width: 80%;
  }
}
@media (max-width: 1399px){
  .view-more-events a{
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
  }
  .top-categories-section-wrapper .container{
    padding: 30px 40px;
  }
  .top-categories-wrapper .section-title{
    font-size: 22px;
    line-height: 26px;
  }
}
@media (max-width: 1200px){
  .search-wrapper .header-search .search-btn{
    left: 10px;
    top: 12px;
  }
  .top-categories-section-wrapper{
    margin-top: -60px;
  }
}
@media (max-width: 1199px){
  .top-categories-wrapper .card-body{
    padding: 7px 0px 0px 7px;
    height: 210px;
  }
  .top-categories-wrapper .card-body a{
    padding: 7px 10px;
    margin-right: 7px;
  }
  .top-categories-wrapper .card-body a:not(:last-child) {
    margin-bottom: 5px;
  }
  .top-categories-wrapper .card-event-name{
    font-size: 15px;
    line-height: 20px;
  }
  .top-categories-wrapper .category-name{
    font-size: 13px;
    line-height: 20px;
  }
  .top-categories-wrapper .card-body .no-records-found{
    font-size: 18px;
    line-height: 26px;
  }
  .search-wrapper .header-search .search-btn{
    width: 15px;
    height: 15px;
    background-size: 15px;
    top: 12px;
    left: 10px;
  }
  .top-categories-wrapper .card-image{
    height: 170px;
  }
  .top-categories-section-wrapper .container{
    padding: 20px 30px;
  }
}
@media (max-width: 1024px){
  .section-event-category .section-content.home-category-list ul li{
    width: 30%;
    margin-bottom: 30px;
  }
  .section-event-category .section-content.home-category-list ul li:nth-last-child(-n+2){
    margin-bottom: 0px;
  }
  .event-category-wrapper{
    flex-wrap: wrap;
  }
  /*.top-categories-section{
    padding: 30px 0;
  }*/
  .hero-1.banner-home{
    height: 250px;
  }
  .hero-1 .hero-content{
    padding: 20px 10px 0;
  }
  .top-categories-section-wrapper .container{
    padding: 20px;
  }
  .top-categories-wrapper .section-title{
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 6px 0;
  }
}
@media (max-width: 991px){
  .section-event-category .section-content.home-category-list ul li{
    width: 29%;
  }
  .section-event-category .section-content ul li span.cate-name label {
    height: 70px;
    line-height: 22px;
  }
  .performers-slide-item .performer-name{
    font-size: 14px;
  }
  .top-categories-wrapper .row{
    flex-wrap: wrap;
    justify-content: center;
  }
  .top-categories-wrapper .row .near-event-wrapper{
    width: 48%;
  }
  .top-categories-wrapper .row .near-event-wrapper:last-child{
    margin-top: 40px;
  }
}
@media (max-width: 768px){
  .top-categories-section{
    padding: 0 0 35px 0;
  }
  .section-event-category {
    padding: 35px 15px;
  }
}
@media (max-width: 767px){
  .hero-1 .hero-content .hero-title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 7px;
  }
  .section-event-category .section-header h2{
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 20px;
  }
  .section-event-category .section-content.home-category-list ul li a .category-wrap .cate-name label {
    font-size: 18px;
  }
  .performer-slider-details .search-ticket-btn{
    font-size: 12px;
  }
  .section-event-category .section-content.home-category-list ul li{
    width: 27%;
    margin: 0 15px 30px;
  }
  .location-filter{
    justify-content: center;
  }
  .top-categories-wrapper .row .near-event-wrapper{
    width: 100%;
  }
  .top-categories-wrapper .row .near-event-wrapper:not(:last-child){
    margin-bottom: 40px;
  }
  .top-categories-wrapper .row .near-event-wrapper:last-child{
    margin-top: 00px;
  }
  .top-categories-wrapper .section-title{
    font-size: 20px;
    line-height: 24px;
  }
  .nearest-performer-slider{
    min-height: 160px;
  }
  .event-search-location-filter{
    flex-direction: column;
    padding: 15px;
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  .search-wrapper{
    margin: 0 0 10px 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  .banner-home .banner-subtext{
    top: 195px;
  }
  .top-categories-section-wrapper{
    margin-top: -45px;
  }
  .global-search-blur .home-location-wrapper{
    z-index: 1;
  }
  .hero-1.banner-home{
    height: 300px;
  }
  .search-wrapper .header-search .search-btn{
    left: 3px;
  }
  .home-location-wrapper{
    width: 100%;
  }
}
@media (max-width: 575px){
  .section-event-category .section-content.home-category-list ul li{
    width: 42.5%;
    margin-bottom: 20px;
  }
  .section-event-category .section-content.home-category-list ul li:nth-last-child(-n+2){
    margin-bottom: 20px;
  }
  .section-event-category .section-content.home-category-list ul li:last-child{
    margin-bottom: 0px;
  }
  .performers-slide-item{
    padding: 8px 12px;
    height: 110px;
  }
  .top-categories-wrapper .card-body{
    padding: 7px 0px 0px 7px;
    margin-bottom: 7px;
    height: 195px;
  }
  .top-categories-wrapper .card-body a{
    padding: 6px 10px;
  }
  .top-categories-wrapper .card-event-name{
    font-size: 14px;
    line-height: 18px;
  }
  .nearest-performer-slider .pindrop_cssloader_main{
    margin-top: 30px;
  }
  .search-wrapper{
    width: 100%;
    display: flex;
  }
  .search-wrapper .header-search{
    width: 100%;
  }
  .top-categories-section{
    padding: 0 15px 35px 15px;
  }
  .hero-1.banner-home{
    height: 280px;
  }
  .hero-1 .hero-content{
    padding: 30px 15px 0;
  }
  .top-categories-wrapper .row .near-event-wrapper:not(:last-child) {
    margin-bottom: 30px;
  }
}
@media (max-width: 480px){
  .section-event-category .section-content.home-category-list ul li{
    width: 41.5%;
  }
  .section-event-category{
    padding: 25px 10px 30px;
  }
  .section-event-category .section-content ul li{
    float: none;
    width: 100%;
  }
  .performer-slider-details .search-ticket-btn {
    font-size: 13px;
  }
  .performer-slider .tns-controls button span{
    font-size: 18px;
  }
  .view-more-events a{
    padding: 7px 10px;
    font-size: 13px;
    line-height: 18px;
  }
  .view-more-events a i{
    margin-left: 7px;
  }
  .top-categories-wrapper .card-image{
    height: 150px;
  }
  .top-categories-section{
    padding: 0 15px 30px 15px;
  }
  .section-event-category{
    padding: 30px 15px;
  }
}
@media (max-width: 400px){
  .section-event-category .section-content.home-category-list ul li{
    width: 100%;
  }
  .section-event-category .section-content.home-category-list ul li a .category-wrap .cate-name label {
    font-size: 22px;
  }
  .performer-slider-details .search-ticket-btn{
    padding: 5px 12px 4px;
    font-size: 12px;
  }
  .top-categories-wrapper .card-body .no-records-found{
    font-size: 16px;
    line-height: 24px;
  }
  .hero-1 .hero-content .hero-title{
    font-size: 28px;
    margin: 0px 0 8px 0;
  }
  .top-categories-section-wrapper .container{
    padding: 15px;
  }
  .event-search-location-filter{
    padding: 10px;
  }
}
</style>
<template>
  <section class="hero-1 banner-home">
    <div class="hero-content">
      <h1 class="hero-title">Live Events, Lit Prices!</h1>
      <div class="event-search-location-filter">
        <div class="search-wrapper mobile-search-icon-j home-banner-search">
          <div class="hero-search header-search">
            <GlobalSearchAutocomplete/>
            <button v-on:click="searchEvents" class="search-btn" aria-label="home-search-btn" name="home-search-btn" type="submit"></button>
          </div>
        </div>
        <!-- <div class="home-location-wrapper">
          <LocationDropdown @custom-change="customChange"/>
        </div> -->
      </div>
      <BannerSubText />
    </div>
  </section>
  <!-- <section class="location-filter-wrapper">
    <div class="container">
      <div class="location-filter">
      </div>
    </div>
  </section> -->
  <section class="top-categories-section">
    <div class="top-categories-section-wrapper">
      <div class="container">
        <div class="top-categories-wrapper">
          <div class="row" id="regional-data">
            <div class="col-md-4 col-xl-4 near-event-wrapper">
              <h2 class="section-title sports-title-tag">Sports Near You</h2>
              <div class="card">
                <div class="card-content">
                  <div class="card-image image-near-you-sports">
                    <img src="images/top-sports.webp" alt="image" rel="preload"/>
                  </div>
                </div>
                <div class="card-body-wrapper">
                  <div class="card-body card-body-events">
                    <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in nearestPerformersSports" :key="item">
                      <div class="event-slot">
                        <div class="card-event-name">{{ item.name }}</div>
                        <span class="category-name">
                          <span class="event-type-label"><i class="fa fa-tag"></i>Event Type - </span>
                          <span class="category-label">{{ item.category.name }}</span>
                        </span>
                      </div>
                    </router-link>
                    <p class="no-records-found" v-if="nearestPerformersSports.length <= 0 ">No Sports performers found !</p>
                  </div>
                  <div class="view-more-events">
                    <router-link :to="{path: '/events/type/sport'}">View all sports events <i class="fa fa-chevron-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xl-4 near-event-wrapper">
              <h2 class="section-title concerts-title-tag">Concerts Near You</h2>
              <div class="card">
                <div class="card-content">
                  <div class="card-image image-near-you-concerts">
                    <img src="images/top-concerts.webp" alt="image" width="400" height="320" rel="preload" />
                  </div>
                </div>
                <div class="card-body-wrapper">
                  <div class="card-body card-body-events">
                    <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in nearestPerformersConcert" :key="item">
                      <div class="event-slot">
                        <div class="card-event-name">{{ item.name }}</div>
                        <span class="category-name">
                          <span class="event-type-label"><i class="fa fa-tag"></i>Event Type - </span>
                          <span class="category-label">{{ item.category.name }}</span>
                        </span>
                      </div>
                    </router-link>
                    <p class="no-records-found" v-if="nearestPerformersConcert.length <= 0 ">No Concerts performers found !</p>
                  </div>
                  <div class="view-more-events">
                    <router-link :to="{path: '/events/type/concert'}">View all concerts events <i class="fa fa-chevron-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xl-4 near-event-wrapper">
              <h2 class="section-title theater-title-tag">Theater Near You</h2>
              <div class="card">
                <div class="card-content">
                  <div class="card-image image-near-you-theater">
                    <img src="images/top-theater.webp" alt="image" width="400" height="320" rel="preload" />
                  </div>
                  <div class="card-body-wrapper">
                    <div class="card-body card-body-events">
                      <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in nearestPerformersTheater" :key="item">
                        <div class="event-slot">
                          <div class="card-event-name">{{ item.name }}</div>
                          <span class="category-name">
                            <span class="event-type-label"><i class="fa fa-tag"></i>Event Type - </span>
                            <span class="category-label">{{ item.category.name }}</span>
                          </span>
                        </div>
                      </router-link>
                      <p class="no-records-found" v-if="nearestPerformersTheater.length <= 0 ">No Theater performers found !</p>
                    </div>
                    <div class="view-more-events">
                      <router-link :to="{path: '/events/type/theater'}">View all theater events <i class="fa fa-chevron-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-event-category category-wrap">
    <div class="container">
      <div class="section-header">
        <h2>Event by Categories</h2>
      </div>
      <div class="section-content home-category-list">
        <ul class="event-category-wrapper">
          <!-- eslint-enable -->
          <li class="category">
            <router-link :to="{path: '/category/concert'}">
              <div class="category-wrap">
                <img src="images/concerts.webp" alt="image" width="335" height="320" rel="preload">
                <span class="cate-name" href="javascript:void(0)"><label>CONCERT</label></span>
              </div>
            </router-link>
          </li>
          <!-- eslint-enable -->
          <li class="category">
            <router-link :to="{path: '/category/theater'}">
              <div class="category-wrap">
                <img src="images/theater.webp" alt="image" width="335" height="320" rel="preload">
                <span class="cate-name" href="javascript:void(0)"><label>THEATER</label></span>
              </div>
            </router-link>
          </li>
          <!-- eslint-enable -->
          <li class="category">
            <router-link :to="{path: '/performers/nfl-football/1' }" >
              <div class="category-wrap">
                <img src="images/football.webp" alt="image" width="335" height="320" rel="preload">
                <span class="cate-name"><label>NFL</label></span>
              </div>
            </router-link>
          </li>
          <li class="category">
            <router-link :to="{path: '/performers/mlb-baseball/2' }" >
              <div class="category-wrap">
                <img src="images/baseball.webp" alt="image" width="335" height="320" rel="preload">
                <span class="cate-name"><label>MLB</label></span>
              </div>
            </router-link>
          </li>
          <li class="category">
            <router-link :to="{path: '/performers/nba-basketball/3' }" >
              <div class="category-wrap">
                <img src="images/basketball.webp" alt="image" width="335" height="320" rel="preload">
                <span class="cate-name"><label>NBA</label></span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import Cookies from 'js-cookie'
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'
// import LocationDropdown from '../../components/layouts/header/Location.vue'
import GlobalSearchAutocomplete from '../../views/autocomplete/GlobalSearchAutocomplete'

export default {
  name: 'Home',
  beforeCreate () {
    document.title = 'Live Events, Lit Prices  | ' + process.env.VUE_APP_NAME
  },
  components: {
    VLazyImage,
    // LocationDropdown,
    GlobalSearchAutocomplete,
    BannerSubText
  },
  data: function () {
    return {
      performers: [],
      nearestPerformersSports: [],
      nearestPerformersTheater: [],
      nearestPerformersConcert: [],
      areCategoriesLoaded: false,
      arePerformersLoaded: false,
      areNearestPerformersLoaded: false,
      notLoadingPerformers: true,
      userSearchLocation: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : '',
      userLocation: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : '',
      latitude: '',
      longitude:'',
    }
  },
  mounted () {
    if (this.userSearchLocation) {
      this.latitude = this.userSearchLocation.lat
      this.longitude = this.userSearchLocation.lang
    } else {
      if (this.userLocation){
        var location = this.userLocation.loc
        this.latitude = location.split(',')[0]
        this.longitude = location.split(',')[1]
      }
    }
    this.getNearestPerformersSports()
    this.getNearestPerformersTheater()
    this.getNearestPerformersConcert()
  },
  methods: {
    customChange (value) {
      this.nearestPerformers = []
      this.areNearestPerformersLoaded = false
      if (value === 'userSearchLocation'){
        this.userSearchLocation = JSON.parse(Cookies.get('userSearchLocation'))
        this.latitude = this.userSearchLocation.lat
        this.longitude = this.userSearchLocation.lang
      }else{
        var location = this.userLocation.loc
        this.latitude = location.split(',')[0]
        this.longitude = location.split(',')[1]

      }
      this.getNearestPerformersSports()
      this.getNearestPerformersTheater()
      this.getNearestPerformersConcert()
    },
    searchEvents: function (event) {
      if (this.$refs.searchEventInput.value) {
        this.$router.push({
          path: 'events',
          query: { q: this.$refs.searchEventInput.value }
        })
      }
    },
    getNearestPerformersSports: function (event) {
      this.areNearestPerformersLoaded = false
      axios.get(process.env.VUE_APP_API_URL + 'getNearestPerformers', {
        params: {
          lang: this.longitude,
          lat: this.latitude,
          eventType: 'SPORT',
          limit:5
        }
      }).then(response => {
        if (response.status === 200) {
          // this.areNearestPerformersLoaded = true
          this.nearestPerformersSports = response.data.data
          if (response.data.type === 'top'){
            document.getElementsByClassName('sports-title-tag')[0].innerHTML = 'Top Sports'
          }
          if (response.data.type === 'nearest'){
            document.getElementsByClassName('sports-title-tag')[0].innerHTML = 'Top Sports Near You'
          }
        }
      })
    },
    getNearestPerformersTheater: function (event) {
      this.areNearestPerformersLoaded = false
      axios.get(process.env.VUE_APP_API_URL + 'getNearestPerformers', {
        params: {
          lang: this.longitude,
          lat: this.latitude,
          eventType: 'THEATER',
          limit:5
        }
      }).then(response => {
        if (response.status === 200) {
          // this.areNearestPerformersLoaded = true
          this.nearestPerformersTheater = response.data.data
         if (response.data.type === 'top'){
            document.getElementsByClassName('theater-title-tag')[0].innerHTML = 'Top Theaters'
          }
          if (response.data.type === 'nearest'){
            document.getElementsByClassName('theater-title-tag')[0].innerHTML = 'Top Theaters Near You'
          }
        }
      })
    },
    getNearestPerformersConcert: function (event) {
      this.areNearestPerformersLoaded = false
      axios.get(process.env.VUE_APP_API_URL + 'getNearestPerformers', {
        params: {
          lang: this.longitude,
          lat: this.latitude,
          eventType: 'CONCERT',
          limit:5
        }
      }).then(response => {
        if (response.status === 200) {
          // this.areNearestPerformersLoaded = true
          this.nearestPerformersConcert = response.data.data
          if (response.data.type === 'top'){
            document.getElementsByClassName('concerts-title-tag')[0].innerHTML = 'Top Concerts'
          }
          if (response.data.type === 'nearest'){
            document.getElementsByClassName('concerts-title-tag')[0].innerHTML = 'Top Concerts Near You'
          }
        }
      })
    },
  }
}
</script>
